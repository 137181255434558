import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "long-life-libraries",
      "style": {
        "position": "relative"
      }
    }}>{`Long Life Libraries`}<a parentName="h1" {...{
        "href": "#long-life-libraries",
        "aria-label": "long life libraries permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`We’ll begin by looking carefully at the tools we’ll be using throughout this guild, because no builder can work meaningfully without their tools. In particular, we’ll consider the decentralized nature of Git, discuss the beauty and difficulties of Node and all of its millions of packages, and consider the hardhat which will be keeping us safe and the various tools which came before it.`}</p>
    <p>{`However, real builders don’t just need tools: they need plans, blueprints, a vision and direction. For that, we turn to the other critical aspect of our craft: shared libraries. In particular, we’ll be looking at the OpenZeppelin ERC721 library and how we can use it with the help of hardhat. Depending on how this all goes, we may even mention mycelium. One never really knows. `}</p>
    <h2 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}>{`Preparation`}<a parentName="h2" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <ol>
      <li parentName="ol">{`Install `}<a parentName="li" {...{
          "href": "https://git-scm.com/downloads"
        }}>{`Git`}</a>{`, `}<a parentName="li" {...{
          "href": "https://nodejs.org/dist/latest-v12.x/"
        }}>{`Node`}</a>{`, `}<a parentName="li" {...{
          "href": "https://classic.yarnpkg.com/en/docs/install/#mac-stable"
        }}>{`Yarn`}</a>{` and `}<a parentName="li" {...{
          "href": "https://hardhat.org/getting-started/#installation"
        }}>{`Hardhat`}</a>{`.`}</li>
      <li parentName="ol"><em parentName="li">{`Optional`}</em>{`: burn your Windows machine in a cathartic fire ritual and `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=-1h4RNetLbg"
        }}>{`use Linux instead`}</a>{`.`}</li>
    </ol>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Librarians Unite!`}</strong></p>
        <p><img parentName="p" {...{
            "src": "/images/build/LeibnizCombinatorics.gif",
            "alt": "Of Leibniz and Libraries"
          }}></img></p>
        <p>{`Each session in this build guild can be found on its own branch in the repo below:`}</p>
        <Aligner center mdxType="Aligner">
          <Button to="https://github.com/kernel-community/nfteasy" mdxType="Button">Begin</Button>
        </Aligner>
      </Box>
    </InfoBlock>
    <h2 {...{
      "id": "recording",
      "style": {
        "position": "relative"
      }
    }}>{`Recording`}<a parentName="h2" {...{
        "href": "#recording",
        "aria-label": "recording permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Video src="https://www.youtube-nocookie.com/embed/EO5jS3CuEFU" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      